import React, { useState } from 'react';

function Form() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputText, setInputText] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const handleGeneratePlaylist = async () => {
    // Disable the button and start loading animation
    setIsLoading(true);
    setIsDisabled(true);

    try {
      // Simulate a delay for the animation (adjust the duration as needed)
      setTimeout(async () => {
        // Send a request to the backend to generate the playlist
        // Replace this with your actual backend endpoint
        const response = await fetch('https://my-vibe-app-idhjbb5vua-uc.a.run.app/generate_playlist', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Set the content type to JSON
          },
          body: JSON.stringify({ user_input: inputText }), // Send user input as JSON
        });

        if (response.ok) {
          // Successfully generated playlist, handle the callback
          const data = await response.json(); // Replace with actual data extraction
          // Redirect to the received URL or handle as needed
          window.location.href = data.playlist_url;
        } else {
          // Handle error cases
          console.error('Error generating playlist');
          // Optionally, display an error message to the user
        }

        // Re-enable the button and stop loading animation after handling the request
        setIsLoading(false);
      }, 10); // 30000 milliseconds (30 seconds) in this example
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="container">
      <form>
        <textarea
          className="input-text"
          name="user_input"
          placeholder="Tell me how you feel"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          disabled={isDisabled}
        ></textarea>
        <br />
        <button
          type="button"
          className={`submit-button ${isLoading ? 'clicked' : ''}`}
          id="generate-button"
          disabled={isDisabled}
          onClick={handleGeneratePlaylist}
        ></button>
      </form>
    </div>
  );
}

export default Form;
