import React from 'react';

function Footer() {
  return (
    <p className="powered-by">
      Powered by <a className="vibe-italic" href="https://www.linkedin.com/company/myvibeme/" target="_blank" rel="noopener noreferrer">My Vibe</a>
    </p>
  );
}

export default Footer;
