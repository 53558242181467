import React, { useState, useEffect } from 'react';

function Notification() {
  const [notificationIndex, setNotificationIndex] = useState(0);
  const notifications = [
    ["Feel free to reach out at info@myvibe.me to explore this innovation further."],
    ["Click on 'My Vibe' below to reach us on linkedin"],
    // [
    //   "We're in the midst of upgrading our server systems, and as we strive to improve performance,",
    //   "we're actively running a series of tests.",
    //   "We regret any temporary disruptions this might entail.",
    // ],
    // [
    //   "We're currently in the process of enhancing our server infrastructure,",
    //   "and as part of this ongoing effort, we're conducting various tests.",
    //   "We sincerely apologize for any inconvenience this may cause.",
    // ],
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setNotificationIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="notification-container">
      <div className="notification">
        {notifications[notificationIndex].map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    </div>
  );
}

export default Notification;
