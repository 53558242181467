import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Notification from "./Notification";
import Form from "./Form";
import Footer from "./Footer";

function App() {
  return (
    <div>
      <Notification />
      <Form />
      <Footer />
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
